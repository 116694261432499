.login-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 80px 100px 60px 100px;
  border-radius: 8px;
}

@media (min-width: 768px) and (max-width: 991px) {
}
@media (max-width: 767px) {
  .login-card {
    padding: 40px 50px 30px 50px;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
}
