.main-gradient-background {
  background: rgb(177, 165, 143);
  background: linear-gradient(
    120deg,
    rgba(177, 165, 143, 1) 50%,
    rgba(104, 111, 124, 1) 100%
  );
  width: 100vw;
  height: 100vh;
  position: relative;
  padding: 1px;
}
